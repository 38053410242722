
import TitlePage from '@/components/TitlePage'
export default {
  components: {
    TitlePage
  },
  data () {
    return {
    }
  },
  metaInfo () {
    return {
      title: 'Contact',
      titleTemplate: '%s | Kaewsamui resort',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: 'Contact' },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
}
